export const debounce=(func,t)=>{//防抖 高频率触发的事件,在指定的单位时间内，只响应最后一次
    var timeout;
    return function(){
        clearTimeout(timeout)
        timeout=setTimeout(()=>{
            func.apply(this,arguments)
        },t)
    }
}
export const throttle=(func,t)=>{//节流 高频率触发的事件,在指定的单位时间内，只响应第一次
    var last=0
    return function(){
        var now=Date.now()
        if(now>=t+last){
            func.apply(this,arguments)
            last=now
        }else{
            console.log('不满足时差')
        }
    }
}
export const isValidPhoneNumber=(phoneNumber)=>{//判断是否有效手机号
    var regex = /^1[3-9]\d{9}$/;
    return regex.test(phoneNumber);
}